import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "./login.module.scss";

//assets
import fullLogo from "../../assets/images/logo.svg";
import miniLogo from "../../assets/images/minilogo.svg";
import { mainMenuItems } from "../../assets/data/mapItems";
import bookkeepingIcon from "../../assets/images/menu/bookkeeping/bookkeeping.svg";
import googleIcon from "../../assets/images/google.svg";
import uploadIcon from "../../assets/images/icons/upload.svg";
import rightsidelogo from "../../assets/rightsidelogo.svg";
//functionalities
import { loginFunc } from "../../assets/data/api";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context/Globalcontext";
import { useLoadAppDetails } from "../../queryHooks";
import { APP_CODE, APP_USER_TOKEN } from "../../assets/data/constant";
import { useMutation } from "react-query";
import { uploadFileFunc } from "../../assets/functions";
import {
  CustomPasswordInput,
  EachCustomDropdown,
  EachInputDatePicker,
  EachCustomDropdownCountry,
} from "../../components/Custom";
import { getData } from "../../assets/functions/apiCalls";
import { userRegistrationLogin } from "../../assets/functions/authentication";
import { toastify } from "../../assets/functions/toastify";
import axios from "axios";
import transparentLogo from "../../assets/images/registration/transparentlogo.svg";

const Login = () => {
  const {
    loginStep,
    setLoginStep,
    userLoginHandler,
    setGlobalLoading,
    setAccessPages,
  } = useContext(GlobalContext);
  const navigate = useNavigate();
  const fileInputRefNewUser = useRef(null);
  const fileInputRefNewUserGovId = useRef(null);

  const passwordInputRef = useRef(null);

  //page values
  const [emailCredentials, setEmailCredentials] = useState("");
  const [passwordCredentials, setPasswordCredentials] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [menuSelected, setMenuSelected] = useState("");
  const [userRegistration, setUserRegistration] = useState(false);
  const [userRegistrationDetails, setUserRegistrationDetails] = useState({
    email: emailCredentials,
  });
  const [userRegistrationLoading, setUserRegistrationLoading] = useState(false);
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const [allCountries, setAllCountries] = useState([]);

  //image upload
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [uploadFileRFQ1, setUploadFileRFQ1] = useState("");
  const [uploadFileRFQName1, setUploadFileRFQName1] = useState("");
  const [updateLoading1, setUpdateLoading1] = useState(false);

  //login functions
  async function getUserAccessPages() {
    try {
      let response = await axios.get(
        `https://finacebackend.marketsverse.com/api/getTeamPlayerProfile?email=${emailCredentials}`
      );
      let res = response?.data?.data;
      if (response?.data?.teamowner) {
        setAccessPages({ title: "teamowner" });
        localStorage.setItem(
          "accessPages",
          JSON.stringify({ title: "teamowner" })
        );
      } else if (res?.length > 0) {
        if (
          res[0]?.roleDetails?.length > 0 &&
          res[0]?.roleDetails[0]?.staffDetails?.length > 0
        ) {
          setAccessPages(res[0]?.roleDetails[0]?.staffDetails[0]);
          localStorage.setItem(
            "accessPages",
            JSON.stringify(res[0]?.roleDetails[0]?.staffDetails[0])
          );
        } else {
          setAccessPages({ modules: [] });
          localStorage.setItem("accessPages", JSON.stringify({ modules: [] }));
        }
      } else {
        setAccessPages({ modules: [] });
        localStorage.setItem("accessPages", JSON.stringify({ modules: [] }));
      }
      console.log(response?.data, "Get User Access Pages response");
    } catch (error) {
      console.log(error, "Get User Access Pages error");
    }
  }

  const { appByCode: appLoginByCode, appByCodeLoading: loginByCodeLoading } =
    useLoadAppDetails(APP_CODE);

  const handleLoginSuccess = async (data) => {
    localStorage.clear();
    getUserAccessPages();
    setUserRegistrationDetails((prev) => {
      return {
        ...prev,
        username: data?.user?.username,
      };
    });
    localStorage.setItem("accessToken appSpecific", data?.accessToken);
    await userLoginHandler(emailCredentials, data.accessToken, data.idToken);
    Cookies.set(APP_USER_TOKEN, data.idToken);
    let isUserRegistered = await getData(
      `https://finacebackend.marketsverse.com/api/checkuser/${emailCredentials}`
    );

    // alert()

    console.log(isUserRegistered, "isUserRegistered");
    setGlobalLoading(false);
    if (
      isUserRegistered?.message === "User not registered" ||
      isUserRegistered?.status === false
    ) {
      setUserRegistration(true);
      console.log("emailCredentials", emailCredentials);
    } else {
      navigate("/owner/automation/dashboard");
    }
  };

  const {
    isLoading: isLoggingIn,
    mutate: attemptLogin,
    data: loginAttemptResponse,
  } = useMutation(loginFunc, {
    onSuccess: (data) => {
      if (data.status) {
        handleLoginSuccess(data);
      } else {
        setGlobalLoading(false);
        setLoginStep(99);
      }
    },
  });

  const dropdownMenuRef = useRef(null);

  async function getAllCountries() {
    let allCountries = await getData(
      `https://comms.globalxchange.io/coin/vault/countries/data/get`
    );
    setAllCountries(allCountries?.countries);
  }

  useEffect(() => {
    getAllCountries();

    // Function to handle clicks outside the component
    const handleClickOutside = (event) => {
      if (
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target)
      ) {
        setMenuSelected("");
      }
    };

    // Add a click event listener to the document
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  async function signInFunction() {
    if (appLoginByCode) {
      setGlobalLoading(true);

      attemptLogin({
        email: emailCredentials,
        password: passwordCredentials,
      });
    }
  }

  async function signInFunctionKeyDown(event) {
    if (event.key === "Enter") {
      if (appLoginByCode) {
        setGlobalLoading(true);
        attemptLogin({
          email: emailCredentials,
          password: passwordCredentials,
        });
      }
    }
  }

  async function isUserNameAvailable(usernameee) {
    setUserRegistrationLoading(true);
    if (usernameee?.length > 4) {
      let response = await getData(
        `https://finacebackend.marketsverse.com/api/check?username=${usernameee}`
      );
      if (response?.message === "Username available" && response?.success) {
        setUsernameAvailable(true);
      } else {
        setUsernameAvailable(false);
      }
      console.log(response, "response username available");
    } else {
      setUsernameAvailable(false);
    }
    setUserRegistrationLoading(false);
  }

  // Debouncing logic using useEffect
  useEffect(() => {
    let debounceTimer;

    debounceTimer = setTimeout(() => {
      isUserNameAvailable(userRegistrationDetails?.username);
    }, 100); // 100 milliseconds

    return () => clearTimeout(debounceTimer); // Cleanup on unmount or input change
  }, [userRegistrationDetails?.username]);

  return (
    <div className={classNames.loginPage}>
      {userRegistration ? (
        <div
          className={classNames.loginCard}
          style={{ position: "unset", height: "max-content", maxHeight: "95%" }}
        >
          {/* <div
            className={classNames.backButton}
            onClick={() => {
              setUserRegistration(false);
            }}
          >
            Go Back To Login
          </div> */}
          <div
            className={`${classNames.leftCard} ${classNames.userRegistration}`}
          >
            <img
              src={fullLogo}
              alt="fullLogo"
              style={{
                height: "2rem",
              }}
              onClick={() => navigate("/")}
            />
            <div
              className={`${classNames.credentialsContainer} ${classNames.userRegistrationContainer}`}
              style={{ marginTop: "3.5rem", gap: "2.5rem" }}
            >
              <div className={classNames.flexParent}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Full name"
                  value={userRegistrationDetails?.name}
                  onChange={(event) => {
                    setUserRegistrationDetails((prev) => {
                      return {
                        ...prev,
                        name: event?.target.value,
                        email: emailCredentials,
                      };
                    });
                  }}
                />
                <EachCustomDropdown
                  dropdown={["Male", "Female"]}
                  name="Gender"
                  title="Select Gender"
                  stateValue={userRegistrationDetails}
                  setState={setUserRegistrationDetails}
                />
              </div>
              <div className={classNames.flexParent}>
                <div className={classNames.checkDiv}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Username"
                    value={userRegistrationDetails?.username}
                    onChange={(event) => {
                      if (event?.target?.value?.test(/^[a-zA-Z]*$/)) {
                        setUserRegistrationDetails((prev) => {
                          return {
                            ...prev,
                            username: event?.target.value,
                            email: emailCredentials,
                          };
                        });
                      }
                    }}
                  />
                  <div
                    className={classNames.checkLight}
                    style={{
                      background: usernameAvailable
                        ? "var(--main-color)"
                        : "red",
                    }}
                  ></div>
                </div>

                <EachInputDatePicker
                  placeholder="Select birthday"
                  stateValue={userRegistrationDetails}
                  setState={setUserRegistrationDetails}
                  name="birthday"
                />
              </div>
              <div className={classNames.flexParent}>
                <input
                  className={classNames.inputContainer}
                  placeholder="Phone number"
                  maxlength="10"
                  pattern="/^[0-9]*$/"
                  value={userRegistrationDetails?.PhoneNumber}
                  onChange={(event) => {
                    setUserRegistrationDetails((prev) => {
                      return {
                        ...prev,
                        PhoneNumber: event?.target?.value?.replace(
                          /[^0-9]/g,
                          ""
                        ),
                        email: emailCredentials,
                      };
                    });
                  }}
                />
                <EachCustomDropdownCountry
                  dropdown={allCountries}
                  name="Country"
                  title="Select Country"
                  stateValue={userRegistrationDetails}
                  setState={setUserRegistrationDetails}
                  type="obj"
                  stateVal="name"
                  mapVal={{ name: "name", image: "image" }}
                />
              </div>
              <div
                className={classNames.flexParent}
                style={{ alignItems: "unset" }}
              >
                <div style={{ height: "unset" }}>
                  <textarea
                    className={classNames.inputContainer}
                    placeholder="Address"
                    value={userRegistrationDetails?.Address}
                    onChange={(event) => {
                      setUserRegistrationDetails((prev) => {
                        return {
                          ...prev,
                          Address: event?.target.value,
                          email: emailCredentials,
                        };
                      });
                    }}
                    style={{ width: "100%", maxWidth: "100%" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: "space-between",
                      background: "white",
                      border: "none",
                      marginTop: "2rem",
                    }}
                  >
                    <input
                      className={classNames.inputContainer}
                      placeholder="State"
                      value={userRegistrationDetails?.state}
                      onChange={(event) => {
                        if (event?.target?.value?.match(/^[a-zA-Z]*$/)) {
                          setUserRegistrationDetails((prev) => {
                            return {
                              ...prev,
                              state: event?.target.value,
                              email: emailCredentials,
                            };
                          });
                        }
                      }}
                    />{" "}
                    <input
                      className={classNames.inputContainer}
                      placeholder="Pincode"
                      value={userRegistrationDetails?.pincode}
                      onChange={(event) => {
                        setUserRegistrationDetails((prev) => {
                          return {
                            ...prev,
                            pincode: event?.target.value,
                            email: emailCredentials,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
                <div className={classNames.profileContainer}>
                  <div
                    className={classNames.imageInput}
                    style={{ width: "12rem" }}
                  >
                    {uploadFileRFQ ? (
                      <>
                        {/* <input
                          className={classNames.uploadNewPicPlanB}
                          type="file"
                          style={{ display: "none" }}
                          accept=".png,.svg,.jpg,.pdf"
                          onChange={async (e) => {
                            let resFile = uploadFileFunc(
                              e,
                              setUploadFileRFQName,
                              setUpdateLoading,
                              "name"
                            );
                            console.log(await resFile, "res file link await");
                            setUploadFileRFQ(await resFile);
                            let profileFile = await resFile;
                            setUserRegistrationDetails((prev) => {
                              return {
                                ...prev,
                                ProfilePic: profileFile,
                                email: emailCredentials,
                              };
                            });
                          }}
                          ref={fileInputRefNewUser}
                        /> */}

                        <div
                          className={classNames.emptyProfile}
                          onClick={() => {
                            if (fileInputRefNewUser.current) {
                              fileInputRefNewUser.current.click();
                            }
                          }}
                        >
                          <img src={uploadFileRFQ} alt={uploadFileRFQName} />
                          {/* <img
                          src={uploadIcon}
                          alt="uploadIcon"
                          style={{
                            animation: updateLoading
                              ? "heartbeat 1s infinite"
                              : "",
                          }}
                        /> */}
                        </div>
                      </>
                    ) : (
                      <div
                        className={classNames.emptyProfile}
                        onClick={() => {
                          if (fileInputRefNewUser.current) {
                            fileInputRefNewUser.current.click();
                          }
                        }}
                      >
                        <img
                          src={uploadIcon}
                          alt="uploadIcon"
                          style={{
                            animation: updateLoading
                              ? "heartbeat 1s infinite"
                              : "",
                          }}
                        />
                      </div>
                    )}
                    <input
                      className={classNames.uploadNewPicPlanB}
                      type="file"
                      style={{ display: "none" }}
                      accept=".png,.svg,.jpg,.pdf"
                      onChange={async (e) => {
                        let resFile = uploadFileFunc(
                          e,
                          setUploadFileRFQName,
                          setUpdateLoading,
                          "name"
                        );
                        console.log(await resFile, "res file link await");
                        setUploadFileRFQ(await resFile);
                        let profileFile = await resFile;
                        setUserRegistrationDetails((prev) => {
                          return {
                            ...prev,
                            ProfilePic: profileFile,
                            email: emailCredentials,
                          };
                        });
                      }}
                      ref={fileInputRefNewUser}
                    />
                    <div
                      className={classNames.imageLabel}
                      onClick={() => {
                        if (fileInputRefNewUser.current) {
                          fileInputRefNewUser.current.click();
                        }
                      }}
                    >
                      Upload Profile Image
                    </div>
                  </div>
                  <div className={classNames.imageInput}>
                    {uploadFileRFQ1 ? (
                      <div
                        className={classNames.emptyProfileLength}
                        onClick={() => {
                          if (fileInputRefNewUserGovId.current) {
                            fileInputRefNewUserGovId.current.click();
                          }
                        }}
                      >
                        <img src={uploadFileRFQ1} alt={uploadFileRFQName1} />
                      </div>
                    ) : (
                      <div
                        className={classNames.emptyProfileLength}
                        onClick={() => {
                          if (fileInputRefNewUserGovId.current) {
                            fileInputRefNewUserGovId.current.click();
                          }
                        }}
                      >
                        <img
                          src={uploadIcon}
                          alt="uploadIcon"
                          style={{
                            animation: updateLoading1
                              ? "heartbeat 1s infinite"
                              : "",
                          }}
                        />
                      </div>
                    )}
                    <input
                      className={classNames.uploadNewPicPlanB}
                      type="file"
                      style={{ display: "none" }}
                      accept=".png,.svg,.jpg,.pdf"
                      onChange={async (e) => {
                        let resFile = uploadFileFunc(
                          e,
                          setUploadFileRFQName1,
                          setUpdateLoading1,
                          "name"
                        );
                        console.log(await resFile, "res file link await");
                        setUploadFileRFQ1(await resFile);
                        let govId = await resFile;
                        setUserRegistrationDetails((prev) => {
                          return {
                            ...prev,
                            governmentId: govId,
                            email: emailCredentials,
                          };
                        });
                      }}
                      ref={fileInputRefNewUserGovId}
                    />
                    <div
                      className={classNames.imageLabel}
                      onClick={() => {
                        if (fileInputRefNewUserGovId.current) {
                          fileInputRefNewUserGovId.current.click();
                        }
                      }}
                    >
                      Upload Gov ID
                    </div>
                  </div>
                </div>
              </div>
              {userRegistration && (
                <div
                  className={classNames.loginMessage}
                  style={{ display: userRegistration?.message ? "" : "none" }}
                >
                  {userRegistration?.message}
                </div>
              )}
              <div
                className={classNames.flexParent}
                style={{ justifyContent: "space-between" }}
              >
                <button
                  className={classNames.transparentBtn}
                  style={{
                    opacity: userRegistrationLoading ? "0.5" : "",
                    pointerEvents: userRegistrationLoading ? "none" : "",
                    background: "#5A5656",
                    color: "white",
                    width: "max-content",
                    padding: "1rem 3rem",
                  }}
                  onClick={() => {
                    setUserRegistration(false);
                  }}
                >
                  Back
                </button>
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity:
                      userRegistrationDetails?.name == "" ||
                      userRegistrationDetails?.username == undefined ||
                      Object?.keys(userRegistrationDetails)?.length < 12 ||
                      // usernameAvailable == false ||
                      userRegistrationDetails?.Address == "" ||
                      userRegistrationDetails?.Country == "" ||
                      userRegistrationDetails?.Gender == "" ||
                      userRegistrationDetails?.PhoneNumber == "" ||
                      userRegistrationDetails?.ProfilePic == "" ||
                      userRegistrationDetails?.birthday == "" ||
                      userRegistrationDetails?.email == "" ||
                      userRegistrationDetails?.governmentId == "" ||
                      userRegistrationDetails?.state == "" ||
                      userRegistrationDetails?.pincode == "" ||
                      userRegistrationDetails?.username == ""
                        ? "0.3"
                        : userRegistrationLoading
                        ? "0.5"
                        : "",
                    pointerEvents: userRegistrationLoading ? "none" : "",
                    width: "max-content",
                    padding: "1rem 3rem",
                  }}
                  onClick={async () => {
                    if (
                      userRegistrationDetails?.name != "" &&
                      userRegistrationDetails?.username != undefined &&
                      Object?.keys(userRegistrationDetails)?.length > 11 &&
                      // usernameAvailable &&
                      userRegistrationDetails?.Address != "" &&
                      userRegistrationDetails?.Country != "" &&
                      userRegistrationDetails?.Gender != "" &&
                      userRegistrationDetails?.PhoneNumber != "" &&
                      userRegistrationDetails?.ProfilePic != "" &&
                      userRegistrationDetails?.birthday != "" &&
                      userRegistrationDetails?.email != "" &&
                      userRegistrationDetails?.governmentId != "" &&
                      userRegistrationDetails?.state != "" &&
                      userRegistrationDetails?.pincode != "" &&
                      userRegistrationDetails?.username != ""
                    ) {
                      setUserRegistrationLoading(true);
                      let objj = {
                        ...userRegistrationDetails,
                        email: emailCredentials,
                      };
                      console.log(
                        objj,
                        "userRegistrationDetails",
                        emailCredentials
                      );
                      let response = await userRegistrationLogin(objj);
                      if (response?.success) {
                        toastify(`User created succesfully`, "success");
                        navigate("/owner/meetings/upcoming");
                      } else {
                        toastify(`Error while creating user!`, "error");
                        setUserRegistration(response);
                      }
                      setUserRegistrationLoading(false);
                      console.log(response, "user registration response");
                    }
                  }}
                >
                  {userRegistrationLoading ? "Loading..." : "Next"}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={classNames.loginCard}>
          <div className={classNames.leftCard}>
            <img
              src={fullLogo}
              alt="fullLogo"
              style={{
                height: "2rem",
              }}
              onClick={() => navigate("/")}
            />
            {loginStep === 1 ? (
              <div className={classNames.credentialsContainer}>
                <div className={classNames.title}>Sign In</div>
                <input
                  className={classNames.inputContainer}
                  placeholder="Email"
                  type="email"
                  value={emailCredentials}
                  onChange={(event) => {
                    setEmailCredentials(event?.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setLoginStep(2);

                      setTimeout(() => {
                        if (passwordInputRef?.current) {
                          passwordInputRef.current.focus();
                        }
                      }, 10);
                    }
                  }}
                />
                <button
                  className={classNames.submitBtn}
                  onClick={() => {
                    setLoginStep(2);

                    setTimeout(() => {
                      if (passwordInputRef?.current) {
                        passwordInputRef.current.focus();
                      }
                    }, 10);
                  }}
                >
                  Next
                </button>
                <div className={classNames.normalBtn}>Forgot Password?</div>
                {/* <div className={classNames.seperator}>
                  <span>OR</span>
                </div>
                <div className={classNames.transparentBtn}>
                  <img src={googleIcon} alt="googleIcon" />
                  Continue With Google
                </div> */}
              </div>
            ) : loginStep === 2 || loginStep === 99 ? (
              <div className={classNames.credentialsContainer}>
                <div className={classNames.title}>Sign In</div>
                <input
                  className={classNames.inputContainer}
                  placeholder="Email"
                  value={emailCredentials}
                  onChange={(event) => {
                    setEmailCredentials(event?.target.value);
                  }}
                />
                {/* <div className={classNames.passwordBox}>
                  <input
                    className={classNames.inputContainer}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    ref={passwordInputRef}
                    value={passwordCredentials}
                    onChange={(event) => {
                      setPasswordCredentials(event?.target.value);
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        signInFunction();
                      }
                    }}
                  />
                </div> */}
                <CustomPasswordInput
                  placeholder="Password"
                  ref={passwordInputRef}
                  stateValue={passwordCredentials}
                  setState={setPasswordCredentials}
                  func={signInFunctionKeyDown}
                  widthh="80%"
                  maxWidth="450px"
                  id="passwordInputRef"
                />

                {loginStep === 99 && (
                  <div className={classNames.loginMessage}>
                    Enter valid credentials!
                  </div>
                )}
                <button
                  className={classNames.submitBtn}
                  style={{
                    opacity: isLoggingIn ? "0.5" : "",
                    pointerEvents: isLoggingIn ? "none" : "",
                  }}
                  onClick={() => {
                    signInFunction();
                  }}
                >
                  {isLoggingIn ? "Loading..." : "Sign In"}
                </button>
                <div className={classNames.normalBtn}>Forgot Password?</div>
                {/* <div className={classNames.seperator}>
                  <span>OR</span>
                </div>
                <div className={classNames.transparentBtn}>
                  <img src={googleIcon} alt="googleIcon" />
                  Continue With Google
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={classNames.rightCard}>
            <img src={transparentLogo} alt="transparentLogo" />
          </div>
          <div className={classNames.bottomMessage}>
            Don’t Have A Finace Account?{" "}
            <Link
              // to="/registration"
              onClick={() =>
                window.open("https://finace.io/registration", "_self")
              }
            >
              Sign Up Now
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
